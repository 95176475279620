import React from 'react';
import styled from 'styled-components';

import media from '@/styles/media';
import scale from '@/styles/scale';

import Heading from '@/atoms/Heading';
import Grid from '@/atoms/Grid';

import TagList from '@/components/TagList';

const Container = styled(Grid.Item)`
  margin-bottom: ${scale(3)};

  ${media.md`
    margin-bottom: ${scale(6)};
  `}
`;

const Wrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.base};
  padding: ${scale(2)} 0 ${scale(3)};

  ${media.md`
    padding: ${scale(5)} 0 ${scale(5.5)};
  `}
`;

const Headline = styled(Heading)`
  margin-bottom: ${scale(1)};

  ${media.md`
    margin-bottom: ${scale(1.5)};
  `}
`;

export default ({ tags, headline, ...props }) => (
  <Container w={[1]} {...props}>
    <Wrapper>
      {headline && (
        <Headline h={3} as="h2">
          {headline}
        </Headline>
      )}

      {tags && <TagList tags={tags} size="large" />}
    </Wrapper>
  </Container>
);
