import React from 'react';
import Imgix, { Picture, Source } from 'react-imgix';

import theme from '@/styles/theme';
import { getImageUrl } from '@/utils/images';

const srcVertical = getImageUrl('frontpage-190906-LG.jpg');
const srcHorizontal = getImageUrl('frontpage-190906-XS.jpg');

export default props => (
  <Picture {...props}>
    <Source
      src={srcVertical}
      sizes="40vw"
      htmlAttributes={{ media: `(min-width: ${theme.breakpoints[1]})` }}
    />

    <Imgix
      src={srcHorizontal}
      sizes="100vw"
      htmlAttributes={{ alt: 'Wonderful World of Wine' }}
    />
  </Picture>
);
