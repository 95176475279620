import React from 'react';
import styled from 'styled-components';

import NavigationLink from '@/atoms/NavigationLink';
import TagLink from '@/atoms/TagLink';
import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';

import media from '@/styles/media';
import scale from '@/styles/scale';

const Title = styled(Heading).attrs({ h: 'productSmall' })`
  margin-bottom: ${scale(0.125)};

  ${media.md`
    margin-bottom: ${scale(0.25)};
  `}
`;

const TitleLink = styled(NavigationLink)`
  :hover,
  :focus {
    border-bottom: 1px solid ${props => props.theme.color.base};
  }
`;

export default ({
  name,
  producer,
  producerNavigationTags,
  productNavigationTags,
  onClick,
  extendNavigationTags = false,
  ...props
}) => (
  <header {...props}>
    <Title as="h1">
      <TitleLink
        rel="bookmark"
        tags={productNavigationTags}
        extend={extendNavigationTags}
        onClick={onClick}
      >
        {name}
      </TitleLink>
    </Title>

    <Text t={-1} as="h2">
      <TagLink tags={producerNavigationTags} extend={extendNavigationTags}>
        {producer}
      </TagLink>
    </Text>
  </header>
);
