import React from 'react';
import styled from 'styled-components';

import dict from '@/dict';

const Label = styled.span`
  display: inline-block;
  border: 1px solid ${props => props.theme.color.base};
  border-radius: 50%;
  padding: 0 0.4em;
  height: 1.3em;
  min-width: 2em;
  font-size: 0.77em;
  line-height: 1.2em;
  text-transform: uppercase;
  text-align: center;
`;

export default ({ countrySlug, ...props }) => (
  <Label {...props}>{dict(countrySlug, 'countryCode')}</Label>
);
