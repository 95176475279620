import styled from 'styled-components';

import NavigationLink from '@/atoms/NavigationLink';

export default styled(NavigationLink)`
  position: relative;
  display: inline-block;

  ::before {
    position: absolute;
    z-index: -1;
    left: -0.45em;
    top: 50%;
    display: block;
    content: '';
    margin-top: -0.7em;
    padding: 0 0.45em;
    width: 100%;
    height: 1.4em;
    border-radius: 0.7em;
    background: ${props => props.theme.color.grayLight};
    opacity: 0;
    transition: 0.1s all ease-out;
  }

  :hover,
  :focus {
    ::before {
      opacity: 1;
    }
  }
`;
