import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import scale from '@/styles/scale';
import media from '@/styles/media';

import { getRegionString, getWinemakerString } from '@/utils/stringTransforms';

import Grid from '@/atoms/Grid';

import Image from './Image';
import Header from './Header';
import Details from './Details';
import PurchaseOptions from './PurchaseOptions';

import { getSanitizedPrice } from '@/utils/pricing';

const Product = styled(Grid.Item)`
  margin-bottom: ${scale(1.5)};

  ${media.md`
    margin-bottom: ${scale(2)};
  `}

  ${media.belowsm`
    ${props =>
      props.hideOnMobile &&
      `
      display: none;
    `}
  `}
`;

export default ({
  slug,
  sku,
  name,
  vintage,
  producer,
  type,
  color,
  bottleSize,
  shopifyProduct = null,
  images = [],
  w = [1 / 2, 1 / 3, 1 / 3],
  baseNavigationTags = null,
  hideOnMobile = false,
  ...props
}) => {
  const region = getRegionString(producer.regions);
  const { slug: countrySlug } =
    producer.regions.length && producer.regions[0].country;
  const image = images.length ? images[0] : 'placeholder.jpg';
  const isAvailable = shopifyProduct && shopifyProduct.availableForSale;

  const nameString = vintage ? [name, vintage].join(' ') : name;
  const producerString = producer.winemakerMoreCommon
    ? `${producer.name} (${getWinemakerString(producer.winemakers)})`
    : producer.name;

  const productNavigationTag = {
    type: 'product',
    slug: `${producer.slug}/${slug}`,
  };

  // if there's any base navigation tags we want to make sure the navigation link will replace currently active tags
  const extendNavigationTags =
    !baseNavigationTags || baseNavigationTags.length === 0;

  const navigationTags = baseNavigationTags
    ? [...baseNavigationTags, productNavigationTag]
    : [productNavigationTag];

  const handleProductClick = () => {
    window.dataLayer.push({
      event: 'productClick',
      ecommerce: {
        click: {
          products: [
            {
              name: nameString,
              id: sku,
              sku,
              handle: `${slug}-${producer.slug}`,
              price:
                isAvailable &&
                getSanitizedPrice(
                  shopifyProduct.priceRange.minVariantPrice.amount
                ),
              brand: producer.name,
            },
          ],
        },
      },
    });
  };

  return (
    <Product w={w} as="article" hideOnMobile={hideOnMobile} {...props}>
      <Image
        file={image}
        navigationTags={navigationTags}
        extendNavigationTags={extendNavigationTags}
        onClick={handleProductClick}
        alt={`${nameString} by ${producerString}`}
      />

      <Header
        name={nameString}
        producer={producerString}
        producerNavigationTags={{
          type: 'producer',
          slug: producer.slug,
        }}
        productNavigationTags={navigationTags}
        onClick={handleProductClick}
        extendNavigationTags={extendNavigationTags}
      />

      <Details
        region={region}
        countrySlug={countrySlug}
        type={type}
        color={color}
      />

      <PurchaseOptions
        isAvailable={isAvailable}
        price={shopifyProduct.priceRange.minVariantPrice.amount}
        bottleSize={bottleSize}
      />
    </Product>
  );
};

export const query = graphql`
  fragment WineData on Graph_Wine {
    id # for key
    slug
    sku
    name
    vintage
    producer {
      name
      slug
      winemakerMoreCommon
      winemakers {
        firstName
        lastName
      }
      regions {
        id
        name
        parentRegion {
          id
          name
        }
        country {
          name
          slug
        }
      }
    }
    type
    color
    body # for filters
    bottleSize
    wineVarieties {
      # for filters
      variety {
        slug
      }
    }
    shopifyProduct {
      availableForSale
      priceRange {
        minVariantPrice {
          amount
        }
      }
    }
    styles {
      # for filters
      slug
    }
    images
  }
`;
