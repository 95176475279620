import React from 'react';

import Grid from '@/atoms/Grid';

import ProductTile from './ProductTile';

const productTileWidth = {
  default: [1 / 2, 1 / 3, 1 / 3],
  small: [1 / 2, 1 / 3, 1 / 3, 1 / 4],
};

export default ({
  products,
  hideLastOnMobile = false,
  baseNavigationTags,
  tileSize = 'default',
  ...props
}) => (
  <Grid {...props}>
    {products &&
      products.map((product, i) => (
        <ProductTile
          key={product.id}
          baseNavigationTags={baseNavigationTags}
          hideOnMobile={hideLastOnMobile && i === products.length - 1}
          w={productTileWidth[tileSize]}
          {...product}
        />
      ))}
  </Grid>
);
