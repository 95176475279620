import React from 'react';
import styled from 'styled-components';

import media from '@/styles/media';
import scale from '@/styles/scale';

import Grid from '@/atoms/Grid';

import ProductList from '@/components/ProductList';

const Container = styled(Grid.Item)`
  margin-bottom: ${scale(3)};

  ${media.md`
    margin-bottom: ${scale(6)};
  `}
`;

const Products = styled(ProductList)`
  border-bottom: 1px solid ${props => props.theme.color.base};
  padding-bottom: ${scale(1.75)};

  ${media.md`
    padding-bottom: ${scale(3)};
  `}
`;

export default ({
  products,
  baseNavigationTags,
  headline,
  description,
  ...props
}) => (
  <Container w={[1]} {...props}>
    <Products
      noContainerPadding
      products={products}
      baseNavigationTags={baseNavigationTags}
      headline={headline}
      description={description}
    />
  </Container>
);
