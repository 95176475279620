import React from 'react';
import styled from 'styled-components';

import dict from '@/dict';
import scale from '@/styles/scale';

import CountryLabel from '@/atoms/CountryLabel';
import Text from '@/atoms/Text';
import Heading from '@/atoms/Heading';

const TypeInfo = styled(Heading).attrs({ h: 5 })`
  margin-top: ${scale(0.25)};
  margin-bottom: ${scale(0.25)};
`;

export default ({ region, countrySlug, type, color }) => {
  const typeInfo = [];

  type && type !== 'NON_SPARKLING' && typeInfo.push(dict(type, 'type'));
  color && typeInfo.push(dict(color, 'color'));

  return (
    <>
      <Text t={-1}>
        {region} {countrySlug && <CountryLabel countrySlug={countrySlug} />}
      </Text>

      {typeInfo.length > 0 && <TypeInfo as="p">{typeInfo.join(', ')}</TypeInfo>}
    </>
  );
};
