import React from 'react';
import styled from 'styled-components';

import scale from '@/styles/scale';

import { getPriceString, getPricePerLiterString } from '@/utils/pricing';

import Text from '@/atoms/Text';

const Info = styled(Text)`
  flex-shrink: 0;
`;

const PricePerLiter = styled(Text)`
  padding-left: ${scale(0.375)};
  color: ${props => props.theme.color.gray};
`;

export default ({ isAvailable, price, bottleSize }) => (
  <>
    {isAvailable ? (
      <Info>
        {getPriceString(price)}

        {bottleSize && (
          <PricePerLiter t={-2} as="span">
            ({getPricePerLiterString(price, bottleSize)})
          </PricePerLiter>
        )}
      </Info>
    ) : (
      <Info>Sold Out</Info>
    )}
  </>
);
