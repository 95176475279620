import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import FeaturedProducts from '@/components/FeaturedProducts';
import FeaturedTags from '@/components/FeaturedTags';
import FeaturedNewsletter from '@/components/FeaturedNewsletter';

import wineTypeTags from '@/data/wineTypeTags.json';

import Intro from '@/components/Intro';

export default ({ data }) => {
  const {
    boxes,
    jeanMarcDreyer,
    milanNestarec,
    recentlyAdded,
    below18,
    lightRed,
    mosel,
  } = data.graph;

  return (
    <>
      <Intro />

      <Grid.Container>
        <Grid>
          <FeaturedTags
            headline="Start browsing wines by type:"
            tags={wineTypeTags}
          />

          <FeaturedProducts
            products={boxes}
            baseNavigationTags={[
              {
                type: 'producer',
                slug: 'box',
                title: 'box',
              },
            ]}
            headline="Lockdown packages"
            description="For everyone who wants to take advantage of the new lockdown to nourish the mind, do some mental gymnastics or move towards new things: we’ve made a few packages to learn more about grapes, discover new regions and winemakers.
            We hope you are staying positive and testing negative."
          />

          <FeaturedProducts
            products={recentlyAdded}
            baseNavigationTags={[
              {
                type: 'collection',
                slug: 'recently-added',
                title: 'recently added',
              },
            ]}
            headline="Recently added"
            description="Find all the bottles that we recently added to the site."
          />

          <FeaturedProducts
            products={below18}
            baseNavigationTags={[
              {
                type: 'collection',
                slug: 'bottles-below-18-eur',
                title: 'below 18 EUR',
              },
            ]}
            headline="Bottles below 18 EUR"
            description="A selection of bottles that are good to start your adventure in the natural wine world, or that have a price that allows you to drink a few more without breaking the bank."
          />

          <FeaturedProducts
            products={milanNestarec}
            baseNavigationTags={[
              {
                type: 'collection',
                slug: 'milan-nestarec',
                title: 'Milan Nestarec',
              },
            ]}
            headline="Milan Nestarec: rising star of natural wine in the Czech Republic"
            description="After Milan Nestarec took over the 8 hectares of vineyards planted by his father in 2001 the Czech winery quickly gained international acclaim for its clean, refreshing and vibrant skin-contact white wines."
          />

          <FeaturedProducts
            products={mosel}
            baseNavigationTags={[
              {
                type: 'collection',
                slug: 'mosel',
                title: 'Mosel',
              },
            ]}
            headline="Things are changing in Germany’s oldest wine region"
            description="Mosel. One of 13 German wine regions. Known for producing some of the world’s best Rieslings. Conservative. Yet, the last years more and more winemakers are following pioneers like Rita & Rudolf Trossen or Thorsten Melsheimer and started to shake things up."
          />

          <FeaturedProducts
            products={lightRed}
            baseNavigationTags={[
              {
                type: 'collection',
                slug: 'light-red',
                title: 'light red',
              },
            ]}
            headline="Try light reds"
            description="A selection of lighter-styled juicy fruit-forward wines with a nice acidity and low tannins – also known as ‘glou glou’. Think Grenache, Gamay, certain blends and carbonic or semi-carbonic maceration. Wines that are served slighty chilled."
          />

          <FeaturedProducts
            products={jeanMarcDreyer}
            baseNavigationTags={[
              {
                type: 'collection',
                slug: 'jean-marc-dreyer',
                title: 'Jean-Marc Dreyer',
              },
            ]}
            headline="Introducing Jean-Marc Dreyer"
            description="Even in a region, not known to be a home for avant-garde winemakers, you find wines that lift traditional local varieties like Riesling, Pinot Gris, Gewürztraminer, Auxerrois, Sylvaner, Muscat and Pinot Noir to another level."
          />

          <FeaturedNewsletter />
        </Grid>
      </Grid.Container>
    </>
  );
};

export const query = graphql`
  query {
    graph {
      boxes: wines(
        where: {
          id_in: [
            "ckgxtr6r2bo7h0752ssnwqgh3"
            "ckgxtv3y1bo8e0752onga8r2u"
            "ckgxty48sbo900752kppzpmtm"
          ]
        }
      ) {
        ...WineData
      }
      jeanMarcDreyer: wines(
        where: {
          id_in: [
            "ckbm5f2q5qvbu0752kfwuk3yy"
            "ckbm5ij4oqvd10752dbgogpvm"
            "cjrwp90t305i407157mrtskuv"
          ]
        }
      ) {
        ...WineData
      }
      milanNestarec: wines(
        where: {
          id_in: [
            "ckcem8frluave07523d1r7wa5"
            "ckcem8fw5ub0l0752q2wahy6p"
            "ckcem8fxcub220752u96csfgm"
          ]
        }
      ) {
        ...WineData
      }
      recentlyAdded: wines(
        where: {
          id_in: [
            "ckgyw824zboey0752otnbvz6g"
            "ckgyw35rvbodo07522kinddq3"
            "ckgyw4jhrbodz07525g16ka0j"
          ]
        }
      ) {
        ...WineData
      }
      below18: wines(
        where: {
          id_in: [
            "ckb2eiftvor5o075284ylbcnz"
            "ck94ad34ohr6t0752lsd6s974"
            "ck94ad2q2hr1b0752iv4ggvsv"
          ]
        }
      ) {
        ...WineData
      }
      mosel: wines(
        where: {
          id_in: [
            "ck94ad2r4hr2g0752tug0o9pd"
            "ck94ad34khr6e07525ar1o8of"
            "ck94ad2kdhqw30752qya3h36n"
          ]
        }
      ) {
        ...WineData
      }
      lightRed: wines(
        where: {
          id_in: [
            "ckcem8fvguazy0752chg1qqgi"
            "ck94ad2lbhqx407529qq8gqo9"
            "ckbm3zz94qv5q0752ax5hx8hz"
          ]
        }
      ) {
        ...WineData
      }
    }
  }
`;
