import React, { useEffect, useState } from 'react';

import { applyFilters } from '@/utils/productFilters';

import ProductGroup from './ProductGroup';
import ProductGroupsBySection from './ProductGroupsBySection';

export default ({
  products,
  filters = null,
  sections = null,
  sectionFilter = null,
  baseNavigationTags = null,
  hideLastOnMobile = false,
  tileSize = 'default',
  hideSoldOut = false,
  ...props
}) => {
  const [isClient, setClient] = useState(false);

  // we only apply filters after the component has mounted to avoid running into hydration issues: https://reactjs.org/docs/react-dom.html#hydrate
  useEffect(() => {
    setClient(true);
  }, []);

  let filteredProducts =
    !isClient ||
    !filters ||
    typeof filters !== 'object' ||
    Object.keys(filters).length === 0
      ? products
      : applyFilters(products, filters);

  if (hideSoldOut) {
    filteredProducts = filteredProducts.filter(
      product =>
        product.shopifyProduct && product.shopifyProduct.availableForSale
    );
  }

  const productsBySections =
    sections &&
    sections.map(section => ({
      id: section.id,
      name: section.name,
      products: filteredProducts.filter(wine => sectionFilter(section, wine)),
    }));

  return (
    <>
      {productsBySections ? (
        <ProductGroupsBySection
          productsBySections={productsBySections}
          baseNavigationTags={baseNavigationTags}
          hideLastOnMobile={hideLastOnMobile}
          tileSize={tileSize}
          {...props}
        />
      ) : (
        <ProductGroup
          baseNavigationTags={baseNavigationTags}
          products={filteredProducts}
          hideLastOnMobile={hideLastOnMobile}
          tileSize={tileSize}
          {...props}
        />
      )}
    </>
  );
};
