import styled from 'styled-components';

import Heading from '@/atoms/Heading';

import scale from '@/styles/scale';
import media from '@/styles/media';

export default styled(Heading).attrs({
  h: 'h3Display',
})`
  margin-top: ${scale(1.5)};
  margin-bottom: ${scale(1)};
  border-bottom: 1px solid ${props => props.theme.color.base};
  padding-bottom: ${scale(0.25)};

  ${media.md`
    margin-top: ${scale(1)};
    margin-bottom: ${scale(1.5)};
    padding-bottom: ${scale(0.5)};
  `}
`;
