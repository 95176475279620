import React from 'react';
import styled from 'styled-components';

import Logo from '@/atoms/Logo';
import Heading from '@/atoms/Heading';
import Grid from '@/atoms/Grid';
import Marquee from '@/components/Marquee';

import media from '@/styles/media';
import scale from '@/styles/scale';
import theme from '@/styles/theme';

import Image from './Image';

const Wrapper = styled.section`
  margin-top: -${scale(theme.measure.header.height[0])};
  padding-bottom: ${scale(3)};
  background: #ffd7e9;

  ${media.md`
    margin-top: -${scale(theme.measure.header.height[1])};
    padding-top: ${scale(8)};
    padding-bottom: ${scale(6)};
  `};
`;

const IntroMarquee = styled(Marquee)`
  position: absolute;
  z-index: 10;
  top: ${scale(1.25)};
  left: ${scale(4)};
  right: ${scale(1)};

  ${media.md`
    top: ${scale(2)};
    left: ${scale(6)};
    right: ${scale(6)};
  `}
`;

const IntroGrid = styled(Grid)`
  align-items: flex-end;
`;

const ImageHolder = styled(Grid.Item)`
  ${media.belowmd`
    margin: 0 -0.5rem;
    padding: 0;
    width: 100vw;
  `}

  ${media.md`
    order: 3;
  `}

  picture {
    display: block;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
`;

const LogoHolder = styled(Grid.Item)`
  margin-top: -12vw;
  margin-bottom: ${scale(1.5)};

  ${media.md`
    position: absolute;
    top: 0;
    left: 50%;
    margin-top: ${scale(0.5)};
    transform: translateX(-50%);
  `}

  svg {
    width: 100%;
    height: auto;
    stroke-width: 1.7;

    ${media.sm`
      stroke-width: 2;
    `}

    ${media.md`
      stroke-width: 2.2;
    `}

    ${media.lg`
      stroke-width: 2.5;
    `}

    #negativeSpaceA {
      display: none;

      ${media.sm`
        display: block;
      `}
    }
  }
`;

const IntroHeading = styled(Heading)`
  em {
    font-style: normal;
    text-transform: uppercase;
  }
`;

export default props => (
  <Wrapper {...props}>
    <IntroMarquee
      content={[
        'Free shipping on orders over €100',
        'Shipping within Germany 3–5 days',
      ]}
    />

    <Grid.Container>
      <IntroGrid>
        <ImageHolder w={[1, 1, 4 / 12]} o={[0, 0, 0.5 / 12]}>
          <Image />
        </ImageHolder>

        <LogoHolder w={[1]}>
          <Logo />
        </LogoHolder>

        <Grid.Item w={[1, 1, 6.5 / 12]}>
          <IntroHeading h={2}>
            <em>Discover</em> natural wine from established winemakers and the
            new generation; from the familiar to the obscure.
          </IntroHeading>
        </Grid.Item>
      </IntroGrid>
    </Grid.Container>
  </Wrapper>
);
