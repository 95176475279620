import React from 'react';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';

import NewsletterForm from '@/components/NewsletterForm';

import media from '@/styles/media';
import scale from '@/styles/scale';

const Container = styled(Grid.Item)``;

const Wrapper = styled(Grid.Item)`
  border-bottom: 1px solid ${props => props.theme.color.base};
  padding: 0 0 ${scale(3)};

  ${media.md`
    padding: 0 0 ${scale(6)};
  `}
`;

const Newsletter = styled(NewsletterForm)`
  margin: 0 auto;
  max-width: 720px;
`;

export default () => (
  <Container w={[1]}>
    <Wrapper>
      <Newsletter size="large" headline="Join our Newsletter" />
    </Wrapper>
  </Container>
);
