import React from 'react';
import styled, { keyframes } from 'styled-components';

import Text from '@/atoms/Text';

import scale from '@/styles/scale';
import media from '@/styles/media';

const movingContent = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
`;

const Container = styled.div``;

const Marquee = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.theme.color.base};
  height: ${scale(1.75)};
  border-radius: ${scale(0.875)};
  white-space: nowrap;
  overflow: hidden;
`;

const Content = styled(Text)`
  flex-shrink: 0;
  padding: 0 ${scale(2)};
  min-width: 100%;
  text-transform: uppercase;
  text-align: center;
  animation: ${movingContent} 9s linear infinite;

  ${media.md`
    animation-duration: 12s;
  `}
`;

const Sentence = styled.span`
  display: inline-block;

  ::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin: -3px ${scale(0.5)} 0;
    width: ${scale(0.375)};
    height: ${scale(0.375)};
    border-radius: 50%;
    background: ${props => props.theme.color.base};
  }

  :last-child {
    ::after {
      display: none;
    }
  }
`;

export default ({ content, ...props }) => (
  <Container {...props}>
    <Marquee>
      <Content>
        {content.map(sentence => (
          <Sentence key={sentence}>{sentence}</Sentence>
        ))}
      </Content>

      <Content isDuplicate as="div">
        {content.map(sentence => (
          <Sentence key={sentence}>{sentence}</Sentence>
        ))}
      </Content>
    </Marquee>
  </Container>
);
