import React from 'react';

import ProductGroup from './ProductGroup';
import SectionTitle from './SectionTitle';

export default ({ productsBySections, ...props }) => (
  <>
    {productsBySections.map(section =>
      section.products.length ? (
        <div key={section.id}>
          <SectionTitle as="h2">{section.name}</SectionTitle>

          <ProductGroup products={section.products} {...props} />
        </div>
      ) : null
    )}
  </>
);
