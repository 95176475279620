import dict from '@/dict';

/**
 * Formats regions into string in the form of "Parent-Region, Sub-Region".
 *
 * @param {array} regions Array with regions objects. Objects must include parentRegion.
 */
export const getRegionString = (regions, includeCountry = false) => {
  if (!regions.length) {
    return '';
  }

  const parentRegion = regions.find(region => region.parentRegion === null);
  const subRegion = regions.find(region => region.parentRegion !== null);
  const regionsArray = subRegion
    ? [parentRegion.name, subRegion.name]
    : [parentRegion.name];

  if (includeCountry) {
    regionsArray.push(parentRegion.country.name);
  }

  return `${regionsArray.join(', ')}`;
};

/**
 * Formats winemaker names like this: Axel Prüfer, Andréa Calek & Martin Wörner
 *
 * @param {array} winemakers array of winemaker-objects, each containing firstName and lastName
 */
export const getWinemakerString = winemakers => {
  let names;

  // make sure we ommit the last name, if it's the same
  if (winemakers.every(w => w.lastName === winemakers[0].lastName)) {
    names = winemakers.map((winemaker, i) =>
      i === winemakers.length - 1
        ? [winemaker.firstName, winemaker.lastName].join(' ')
        : winemaker.firstName
    );
  } else {
    names = winemakers.map(winemaker =>
      [winemaker.firstName, winemaker.lastName].join(' ')
    );
  }

  if (names.length > 2) {
    const last = names.pop();

    return `${names.join(', ')} & ${last}`;
  }

  return names.join(' & ');
};

/**
 * Formats serving info into sentence
 *
 * @param {string} drinkingTemperature use with dict
 * @param {boolean} turnBottle
 * @param {boolean} decant
 */
export const getServingInfoString = (
  drinkingTemperature = null,
  turnBottle = false,
  decant = false
) => {
  const servingInfo = [];

  drinkingTemperature &&
    servingInfo.push(dict(drinkingTemperature, 'temperature'));
  decant && servingInfo.push('decant before drinking');
  turnBottle &&
    servingInfo.push('tip upside down – and back – before drinking');

  return servingInfo.length ? servingInfo.join(' and ') : null;
};
