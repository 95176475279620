import React from 'react';
import styled from 'styled-components';

import NavigationLink from '@/atoms/NavigationLink';

import LazyImage from '@/components/LazyImage';

import { getImageUrl, getImageTransformation } from '@/utils/images';

import scale from '@/styles/scale';
import media from '@/styles/media';

const ImageLink = styled(NavigationLink)`
  display: block;
  margin-bottom: ${scale(0.5)};
  outline: none;

  ${media.md`
    margin-bottom: ${scale(0.625)};
  `}
`;

const Image = styled(LazyImage)`
  display: block;
  width: 100%;
  height: auto;
`;

export default ({
  file,
  navigationTags,
  extendNavigationTags = false,
  alt,
  ...props
}) => {
  return (
    <ImageLink
      tabIndex="-1"
      rel="bookmark"
      tags={navigationTags}
      extend={extendNavigationTags}
      {...props}
    >
      <Image
        placeholder="product"
        alt={alt}
        src={getImageUrl(file)}
        sizes="(min-width: 40em) 33vw, 50vw"
        imgixParams={getImageTransformation(file, 'grid')}
      />
    </ImageLink>
  );
};
